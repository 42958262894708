import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import linearGradient from "assets/theme/functions/linearGradient";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import defaultProfile from 'assets/images/default-profile.svg'

// Images
import googlePlay from "assets/images/google_play.png";
import appStore from "assets/images/app_store.png";
import { apiUrl } from "helpers/const";

function getProperUrl(url){
  if(url.substr(0, 8) !== "https://" && url.substr(0, 7) !== "http://"){
    return "https://"+url;
  } else {
    return url;
  }
}

function PublicProfile() {
  const [profileData, setProfileData] = useState("");

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const profileId = queryParameters.get("profile")
    let fetchAddress = apiUrl
    fetch(fetchAddress + "profile/" + profileId, {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      method: "GET"
    })
      .then(async response => {
        if (!response.ok) {
          const text = await response.text();
          toast.error(JSON.parse(text).error)
          return;
        } else {
          const fetchProfileData = await response.json();
          if (fetchProfileData != null) {
            setProfileData(fetchProfileData)
          }
        }
      })
      .catch(async response => {
        const text = response.text;
        if (response.status != null) {
          switch (response.status) {
            case 500: response.errorMessage = response.statusText + " (" + response.status + ")"; break;
            default: response.errorMessage = JSON.parse(text).error; break;
          }
        }
        if (response.message != null) response.errorMessage = "Brak połączenia z serwerem"
        toast.error("Błąd! " + response.errorMessage)
        return;
      })
  }, []);

  let bgColor = "grey"

  return (
    <MDBox display="flex" flexDirection="column">
      {profileData != null && profileData.photo_url != null && <MDBox display="flex" flexDirection="column" marginTop="40px" alignItems="center">
        <MDBox
            component="img" borderRadius="30px" src={profileData.photo_url} alt="Zdjęcie profilowe"
            sx={{
              width: {
                xs: 80, sm: 80, md: 110, lg: 110, xl: 110, xxl: 110
              },
              height: {
                xs: 80, sm: 80, md: 110, lg: 110, xl: 110, xxl: 110
              }
            }}
          /></MDBox>}
      
      {(profileData != null) &&<MDTypography display="flex" alignItems="center" flexDirection="column" variant="h5" color="dark-grey" fontWeight="medium" sx={{ mt: 3, mb: 5, pb: 1 }}>
        {profileData.name + " " + (profileData.surname ? profileData.surname : "")}
      </MDTypography>}
      
        {/* <MDBox component="img" src={brand} alt="Brand" width="2rem" /> */}

        {(profileData != null && profileData.about_me != null) && <MDBox display="flex" flexDirection="column" alignItems="center">
          <MDTypography variant="div" fontWeight="800" color="black" fontFamily='"Montserrat", sans-serif!important'
              sx={{
                fontSize: {
                  xs: 12,
                  sm: 12,
                  md: 14,
                  lg: 14,
                  xl: 14,
                  xxl: 14
                },
                fontWeight: 600
              }}
            >
              O mnie
            </MDTypography>
            <MDTypography variant="div" fontWeight="600" color="black" fontFamily='"Montserrat", sans-serif!important'
              sx={{
                fontSize: {
                  xs: 12,
                  sm: 12,
                  md: 14,
                  lg: 14,
                  xl: 14,
                  xxl: 14
                }
              }}
            >
              {profileData.about_me}
            </MDTypography>
        </MDBox>}

        {(profileData != null && profileData.user_links != null && profileData.user_links.length) && <MDBox display="flex" flexDirection="column" marginTop="40px" alignItems="center">
          <MDTypography variant="div" fontWeight="800" color="black" fontFamily='"Montserrat", sans-serif!important'
              sx={{
                fontSize: {
                  xs: 12,
                  sm: 12,
                  md: 14,
                  lg: 14,
                  xl: 14,
                  xxl: 14
                },
                fontWeight: 600
              }}
            >
              Linki
            </MDTypography>
            {profileData.user_links.map((link, idx) => <MDBox key={"linkBtn"+idx} pt={2} px={2} display="flex" alignItems="center"><MDButton variant="outlined" color="info" size="small" fullWidth onClick={() => window.open(getProperUrl(link.url), "_blank")}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div>{link.title}</div>
                  <div style={{fontSize: 8}}>{link.description}</div>
                </div>
              </MDButton></MDBox>)}
        </MDBox>}



      
      <MDBox /* position="absolute" bottom="50px"  */width="100%" display="flex" flexDirection="column" alignItems="center" marginTop="100px" marginBottom="50px">
        <MDBox display="flex" flexDirection="row" alignSelf="center">
          <MDBox 
            component="img" 
            cursor="pointer"
            src={googlePlay} 
            style={{cursor: "pointer"}}
            onClick={() => window.open("https://play.google.com/store/apps/details?id=com.lulus1511.businesscards&hl=pl", "_blank")}
            alt="Pobierz z Google Play" 
            sx={{
              width: {
                xs: 100,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 100,
                xxl: 100
              }
            }}
          />
          <MDBox width="5pt"></MDBox>
          <MDBox 
            component="img"
            cursor="pointer"
            src={appStore} 
            onClick={() => window.open("https://apps.apple.com/pl/app/e-wizyt%C3%B3wki/id6670243624?l=pl", "_blank")}
            style={{cursor: "pointer"}}
            alt="Pobierz z App Store" 
            sx={{
              width: {
                xs: 100,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 100,
                xxl: 100
              }
            }} 
          />
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default PublicProfile;
